const num_word = (value: number, words: string[]) => {
  const initialValue = value;
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return `${initialValue} ${words[2]}`;
  if (num > 1 && num < 5) return `${initialValue} ${words[1]}`;
  if (num == 1) return `${initialValue} ${words[0]}`;
  return `${initialValue} ${words[2]}`;
};

export { num_word };
