import create from 'zustand';
import { User } from './models/User';
import { createClient, SupabaseClient } from '@supabase/supabase-js';

interface Store {
  client: SupabaseClient;
  user: User | null;
  setUser: (user: User | null) => void;
}

const useStore = create<Store>()((set) => ({
  client: createClient(
    'https://wmxhnstbgfqgiurwfvyx.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndteGhuc3RiZ2ZxZ2l1cndmdnl4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjM1MDYzMzEsImV4cCI6MTk3OTA4MjMzMX0.QPUUQNeSfYU55HiJY-1qoFj_FLbtvct3k_c9BRZBq5U'
  ),
  user: null,
  setUser: (user) => set({ user }),
}));

export { useStore };
