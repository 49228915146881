import React, { memo, useEffect, useState } from 'react';

import logo from '../../shared/assets/logo.svg';
import qr from '../../shared/assets/qr.svg';

import { useStore } from '../../shared/repository/store';
import { Edit2, Gift } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { QRCodePopup } from '../../features/QrCodePopup';
import { GetPoints } from './ui/GetPoints';
import { NavBar } from '../../shared/components/Navbar';
import { num_word } from '../../shared/helpers/num_words';
import { TODAY } from '../../shared/constants';
import { ONE_TIME_TRANSACTIONS } from '../Operator';

function Main() {
  const { client, user, setUser } = useStore();
  const [tab, setTab] = useState(TODAY);
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      setLoading(true);
      const { data } = await client
        .from('transactions')
        .select()
        .eq('created_for', user!.id);

      if (data !== null) {
        setUser({ ...user!, transactions: data });
      }
      setLoading(false);
    };

    syncer();
  }, [client, user?.id]);

  if (!user) {
    return null;
  }

  const userName = user.name.split(' ')[0];
  const isOperator = user.role === 'operator' || user.role === 'admin';
  const isAdmin = user.role === 'admin';

  const balance = (user.transactions ?? [])
    .filter((t) => t.day === TODAY || ONE_TIME_TRANSACTIONS.includes(t.type))
    .reduce((acc, item) => acc + item.amount, 0);

  return (
    <>
      <div className="flex flex-col pt-8 min-h-screen">
        <div className="flex justify-between relative">
          <img src={logo} alt="RT Logo" />
        </div>

        <div className="flex flex-1 flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 rounded-t-4xl pb-20">
          <div className="flex items-center">
            <NavLink
              to="/profile"
              style={{
                backgroundImage: `url(${user.photo})`,
              }}
              className="relative flex-shrink-0 w-16 h-16 bg-gray-100 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center">
              <div className="absolute right-0 bottom-0 w-5 h-5 rounded-full bg-primary text-white flex items-center justify-center">
                <Edit2 className="w-2.5 h-2.5" />
              </div>
            </NavLink>
            <div className="flex flex-col ml-3 mr-2">
              <p className="text-base font-medium">
                Добрый день,{' '}
                <span className="whitespace-nowrap">{userName}</span>!
              </p>
              <p className="text-xl text-secondary font-bold h-7">
                {loading && balance === 0 ? (
                  <span className="inline-block mt-1 w-4 h-4 rounded-full border-2 border-secondary border-t-transparent animate-spin" />
                ) : (
                  num_word(balance, ['купон', 'купона', 'купонов'])
                )}
              </p>
            </div>
            <button
              onClick={() => setShowQR(true)}
              className="w-10 h-10 rounded-lg bg-primary ml-auto flex items-center justify-center">
              <img src={qr} alt="QR Code" />
            </button>
          </div>

          <div className="flex w-full space-x-2">
            {isOperator && (
              <NavLink
                to="/operator"
                className="mt-4 rounded-md bg-secondary w-full h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center">
                Интерфейс оператора
              </NavLink>
            )}
            {isAdmin && (
              <NavLink
                to="/lottery-check"
                className="mt-4 flex-shrink-0 rounded-md bg-secondary w-16 h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center">
                <Gift />
              </NavLink>
            )}
          </div>

          <div className="flex rounded-xl border-2 h-14 mt-5 border-gray-200 bg-gray-200">
            {[4, 5].map((day) => {
              const isActive = day === tab;
              return (
                <button
                  key={day}
                  onClick={() => setTab(day)}
                  style={{ borderRadius: 11 }}
                  className={`w-1/2 text-lg font-bold text-center ${
                    isActive ? 'bg-white' : 'bg-gray-200'
                  }`}>
                  0{day}.03.2023
                </button>
              );
            })}
          </div>

          <GetPoints day={tab} transactions={user.transactions ?? []} />
        </div>
      </div>

      <NavBar />

      {showQR && (
        <QRCodePopup
          value={user.number_id!}
          onClose={() => setShowQR(false)}
          role={user.role}
        />
      )}
    </>
  );
}

const MemoizedMain = memo(Main);
export { MemoizedMain as Main };
