import React, { memo, useEffect, useState } from 'react';

import logo from '../../shared/assets/logo.svg';

import { useStore } from '../../shared/repository/store';

import { NavBar } from '../../shared/components/Navbar';
import { Transaction } from '../../shared/repository/models/Transactions';
import { format } from 'date-fns';
import { TODAY } from '../../shared/constants';
import { ONE_TIME_TRANSACTIONS } from '../Operator';

export const LOTTERY_NUMBER = 7;

type Coupon = {
  id: number;
  type: Transaction['type'];
  created_at?: Date;
};

const DESCRIPTORS = {
  quiz: 'прохождение квиза',
  match: 'прохождение квиза',
  truth: 'прохождение квиза',
  photo: 'публикацию фото',
  wink: 'установку Wink',
  mc: 'активности на стенде',
  bonus: 'регистрацию в личном кабинете',
  vk: 'подписку в VK',
  tg: 'подписку в Telegram',
};

function Lottery() {
  const { client, user } = useStore();
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    const syncer = async () => {
      const { data } = await client
        .from('transactions')
        .select('*')
        .eq('created_for', user!.id);

      const transactions =
        data?.filter(
          (t) => t.day === TODAY || ONE_TIME_TRANSACTIONS.includes(t.type)
        ) ?? [];

      const newCoupons: Coupon[] = [];

      // TODO: Add filter for day and type
      transactions.forEach((transaction: Transaction) => {
        for (
          let couponIndex = 0;
          couponIndex < transaction.amount;
          couponIndex++
        ) {
          const newCoupon: Coupon = {
            id: transaction.id! * LOTTERY_NUMBER * 100 + couponIndex,
            type: transaction.type,
            created_at: new Date(transaction.created_at!),
          };
          newCoupons.push(newCoupon);
        }
      });
      setCoupons(
        newCoupons.sort(
          (a, b) => b.created_at!.getTime() - a.created_at!.getTime()
        )
      );

      setLoading(false);
    };

    setLoading(true);
    syncer();
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col pt-8 min-h-screen">
        <div className="flex justify-between relative">
          <img src={logo} alt="RT Logo" />
        </div>

        <div className="items-center flex flex-1 flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 rounded-t-4xl pb-20">
          <h3 className="text-xl font-bold mb-4">Ваши купоны</h3>
          <p className="text-sm text-center mb-2">
            В конце соревновательного дня мы проведем розыгрыш, в котором примут
            участие все купоны, выданные сегодня. Будут разыгрываться подарки из
            вкладки «Призы».
          </p>
          <p className="text-sm text-center mb-6">
            Следите за событиями на промо-стенде Ростелекома, и пусть вам
            улыбнется сегодня удача!
          </p>
          {loading && (
            <div className="mt-8 mx-auto w-6 h-6 rounded-full border-2 border-secondary border-t-transparent animate-spin" />
          )}

          {coupons.map((coupon) => (
            <div
              key={coupon.id}
              className="relative w-full flex rounded-lg bg-warm p-4 px-7 mb-4">
              <div className="absolute w-6 h-6 rounded-full bg-white -left-3 top-1/2 -mt-3"></div>
              <div className="absolute w-6 h-6 rounded-full bg-white -right-3 top-1/2 -mt-3"></div>

              <div className="w-full flex flex-col">
                <p className="text-xl font-semibold font-lottery mb-0.5">
                  #{coupon.id}
                </p>
                <p className="text-sm text-black/50">
                  Купон за {DESCRIPTORS[coupon.type] ?? 'активность'}
                </p>
              </div>
              <p className="text-xs font-semibold transform rotate-90 font-lottery opacity-50">
                {format(coupon.created_at!, 'HH:mm:ss')}
              </p>
            </div>
          ))}

          {coupons.length === 0 && !loading && (
            <p className="text-sm mt-2 text-center">
              Здесь пока пусто. Перейдите в раздел «Купоны», чтобы узнать, как
              их получить.
            </p>
          )}
        </div>
      </div>
      <NavBar />
    </>
  );
}

const MemoizedLottery = memo(Lottery);
export { MemoizedLottery as Lottery };
