import React, { memo } from 'react';
import { Gift, ShoppingBag, Star } from 'react-feather';
import { NavLink } from 'react-router-dom';

function NavBar() {
  return (
    <div className="flex justify-around fixed bottom-0 left-0 right-0 bg-white h-16 pb-4 border-t px-6 items-center">
      <NavLink
        to="/points"
        className={(props) =>
          `${
            // eslint-disable-next-line react/prop-types
            props.isActive ? 'text-primary' : 'text-gray-500'
          } w-12 flex flex-col items-center`
        }>
        <Star className="w-6 h-6 mt-1" />
        <p className="text-xs font-medium mt-0.5">Купоны</p>
      </NavLink>
      <NavLink
        to="/shop"
        className={(props) =>
          `${
            // eslint-disable-next-line react/prop-types
            props.isActive ? 'text-primary' : 'text-gray-500'
          } w-12 flex flex-col items-center`
        }>
        <ShoppingBag className="w-6 h-6 mt-1" />
        <p className="text-xs font-medium mt-0.5">Призы</p>
      </NavLink>
      <NavLink
        to="/lottery"
        className={(props) =>
          `${
            // eslint-disable-next-line react/prop-types
            props.isActive ? 'text-primary' : 'text-gray-500'
          } w-12 flex flex-col items-center`
        }>
        <Gift className="w-6 h-6 mt-1" />
        <p className="text-xs font-medium mt-0.5">Лотерея</p>
      </NavLink>
      {/* <NavLink
        to="/rating"
        className={(props) =>
          `${
            // eslint-disable-next-line react/prop-types
            props.isActive ? 'text-primary' : 'text-gray-500'
          } w-12 flex flex-col items-center`
        }>
        <Award className="w-6 h-6 mt-1" />
        <p className="text-xs font-medium mt-0.5">Рейтинг</p>
      </NavLink> */}
    </div>
  );
}

const MemoizedNavBar = memo(NavBar);
export { MemoizedNavBar as NavBar };
