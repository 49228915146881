import React, { memo, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Input } from '../../shared/components';
import { TODAY } from '../../shared/constants';
import { num_word } from '../../shared/helpers/num_words';
import { Transaction } from '../../shared/repository/models/Transactions';
import { User } from '../../shared/repository/models/User';
import { useStore } from '../../shared/repository/store';
import { POINTS_DESCRIPTORS } from '../Main/ui/GetPoints';

export const ONE_TIME_TRANSACTIONS = ['bonus', 'vk', 'tg', 'wink'];
const CAN_INCREASE = ['photo', 'mc', 'bonus', 'vk', 'tg', 'wink'];

const DESCRIPTORS = {
  quiz: 'Квиз',
  match: 'квиз',
  truth: 'квиз',
  photo: 'Фотозона',
  wink: 'Wink',
  mc: 'Стенд',
  bonus: 'Программа Бонус',
  vk: 'ВКонтакте',
  tg: 'Telegram',
} as const;

const USER_DESCRIPTORS = {
  quiz: 'прохождение квиза',
  match: 'прохождение квиза',
  truth: 'прохождение квиза',
  photo: 'публикацию фото',
  wink: 'установку Wink',
  mc: 'активности на стенде',
  bonus: 'регистрацию в личном кабинете',
  vk: 'подписку в VK',
  tg: 'подписку в Telegram',
} as const;

function Operator() {
  const initialId = window.location.hash.replace('#', '');

  const { client, user } = useStore();
  const [findedUser, setFindedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [increaseLoading, setIncreaseLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const lastSended = useRef(0);
  const [id, setId] = useState(initialId);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    const syncer = async () => {
      setError(null);
      if (id.length === 0) {
        setFindedUser(null);
        return;
      }
      setLoading(true);
      setFindedUser(null);
      const thisRequest = lastSended.current + 1;
      lastSended.current = thisRequest;

      const { data } = await client
        .from('users')
        .select()
        .eq('number_id', id)
        .single();

      if (thisRequest !== lastSended.current) {
        return;
      }

      if (data) {
        const transactionsResponse = await client
          .from('transactions')
          .select('*')
          .eq('created_for', data.id);
        data.transactions = transactionsResponse.data;
        setFindedUser(data);
      } else {
        setFindedUser(null);
      }
      setLoading(false);
    };
    syncer();
  }, [id, refresh]);

  const onIncrease = async (type: Transaction['type'], amount = 1) => {
    setError(null);
    if (!findedUser || !user) {
      alert('Ошибка добавления транзакции');
      return;
    }
    setIncreaseLoading(true);
    if (type === 'mc' && amount > 1) {
      // Update existing transaction
      const { data, error } = await client
        .from('transactions')
        .update({ amount })
        .eq('unique_id', `${findedUser.id}_${type}_${TODAY}`);
      if (error) {
        setError(error.message);
      }
      if (data) {
        setRefresh({});
      }
    } else {
      // Create new transaction
      const newTransaction: Transaction = {
        type,
        created_by: user!.id,
        created_for: findedUser!.id,
        amount: amount,
        day: TODAY,
        unique_id: `${findedUser!.id}_${type}_${TODAY}`,
      };

      const { data, error } = await client
        .from('transactions')
        .insert(newTransaction);

      await client
        .from('users')
        .update({
          last_interacted_at: new Date().toISOString(),
        })
        .eq('id', findedUser!.id);

      setIncreaseLoading(false);
      if (error) {
        setError(error.message);
      }

      if (data) {
        setRefresh({});
      }
    }
  };

  const notFindedUser = findedUser === null && id.length > 0 && !loading;

  const todayTransactions =
    findedUser?.transactions?.filter(
      (t) => t.day === TODAY || ONE_TIME_TRANSACTIONS.includes(t.type)
    ) ?? [];

  const balance =
    findedUser?.transactions
      ?.filter((t) => t.day === TODAY || ONE_TIME_TRANSACTIONS.includes(t.type))
      .reduce((acc, t) => acc + t.amount, 0) ?? 0;

  if (!user || user.role === 'member') {
    return <Navigate to="/points" />;
  }

  return (
    <>
      <div className="fixed left-0 top-0 right-0 bottom-0 bg-secondary"></div>
      <div className="relative flex flex-col min-h-screen">
        <div className="flex-1 flex flex-col items-center justify-center pt-8">
          {loading ? (
            <div className="w-6 h-6 rounded-full border-2 border-white border-t-transparent animate-spin"></div>
          ) : (
            <>
              {notFindedUser && (
                <div className="flex flex-col text-white w-full">
                  <h1 className="text-2xl text-center font-bold flex-shrink-0">
                    ID не найден
                  </h1>
                  <p className="text-center mt-3 mb-2 flex-shrink-0 px-4">
                    Если номер введен верно, сделайте фото{' '}
                    <span className="whitespace-nowrap">QR-кода</span>{' '}
                    пользователя и отправьте куратору в телеграм
                  </p>
                </div>
              )}
              {findedUser && (
                <div className="flex flex-col items-center w-full mb-auto text-white">
                  <div className="flex w-full">
                    <div
                      style={{
                        backgroundImage: `url(${findedUser.photo})`,
                      }}
                      className="relative flex-shrink-0 w-20 h-20 bg-gray-100 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center"></div>
                    <div className="flex flex-col ml-4 justify-center">
                      <p className="text-xl font-bold">{findedUser.name}</p>
                      <p className="text-base text-white">
                        {num_word(balance, ['купон', 'купона', 'купонов'])}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-col mt-8">
                    {POINTS_DESCRIPTORS.map((item) => {
                      const currentTransaction = todayTransactions.find(
                        (t) => t.type === item.id
                      );

                      return (
                        <button
                          key={item.id}
                          onClick={() => {
                            if (
                              (CAN_INCREASE.includes(item.id) &&
                                !currentTransaction) ||
                              (item.id === 'mc' &&
                                currentTransaction &&
                                currentTransaction.amount < 5)
                            ) {
                              if (
                                confirm(
                                  `Выдать купон за ${
                                    USER_DESCRIPTORS[
                                      item.id as keyof typeof USER_DESCRIPTORS
                                    ]
                                  }?`
                                )
                              ) {
                                onIncrease(
                                  item.id as Transaction['type'],
                                  currentTransaction
                                    ? currentTransaction.amount + 1
                                    : 1
                                );
                              }
                            }
                          }}
                          className={`${
                            currentTransaction ? '' : 'opacity-60'
                          } flex items-center relative w-full text-black rounded-lg bg-warm p-4 px-6 mb-4 h-16`}>
                          <div className="absolute w-6 h-6 rounded-full bg-secondary -left-3 top-1/2 -mt-3"></div>
                          <div className="absolute w-6 h-6 rounded-full bg-secondary -right-3 top-1/2 -mt-3"></div>
                          <div className="relative w-10 h-10 rounded-full bg-gray-200 mr-2 flex-shrink-0">
                            <img
                              src={item.photo}
                              alt="icon"
                              className="w-full h-full absolute rounded-full border"
                            />
                          </div>
                          <p className="text-base font-semibold">
                            {DESCRIPTORS[item.id as keyof typeof DESCRIPTORS]}
                          </p>
                          {currentTransaction && (
                            <p className="text-xl ml-auto font-bold mr-2">
                              &times;&nbsp;
                              {currentTransaction.amount}
                            </p>
                          )}
                        </button>
                      );
                    })}
                  </div>
                  {error && (
                    <div className="w-full p-4 text-sm rounded-lg mt-6 text-center bg-red-600">
                      Ошибка добавления баллов: {error}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <div className="relative flex flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 pt-6 rounded-t-4xl">
          <Input
            name="id"
            title="ID пользователя"
            value={id}
            onChange={(newId) =>
              setId(newId.replace(/[^0-9]/g, '').slice(0, 6))
            }
          />
          {/* <Button
            loading={increaseLoading}
            title={buttonTitle}
            onPress={() => {
              // onIncrease();
            }}
          /> */}
        </div>
      </div>
    </>
  );
}

const MemoizedOperator = memo(Operator);
export { MemoizedOperator as Operator };
