import React, { memo, useEffect, useState } from 'react';
import { RefreshCw, X } from 'react-feather';
import { Navigate } from 'react-router-dom';
import { log } from '../../../shared/helpers/log';
import { num_word } from '../../../shared/helpers/num_words';
import { Transaction } from '../../../shared/repository/models/Transactions';
import { User } from '../../../shared/repository/models/User';
import { useStore } from '../../../shared/repository/store';
import { ShopItem } from '../../Shop';

function ShopStats() {
  const { user, client } = useStore();
  const [transactions, setTransactions] = useState<
    (ShopItem & { count: number })[]
  >([]);
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      setLoading(true);
      const { data } = await client
        .from('transactions')
        .select('id, shop_item(*)')
        .eq('type', 'shop');

      if (!data) {
        setLoading(false);
        return;
      }

      const items: (ShopItem & { count: number })[] = [];
      data.forEach((item) => {
        const found = items.find((i) => i.id === item.shop_item.id);
        if (found) {
          found.count++;
        } else {
          items.push({ ...item.shop_item, count: 1 });
        }
      });

      setTransactions(items.sort((a, b) => b.count - a.count));
      setLoading(false);
    };
    syncer();
  }, [refresh]);

  if (user!.role !== 'admin') {
    return <Navigate to="/points" />;
  }

  return (
    <div className="bg-white w-full rounded-xl p-6 flex flex-col">
      <div className="flex w-full justify-between">
        <h3 className="text-xl font-bold flex items-center">Магазин</h3>
        <button onClick={() => setRefresh({})} className="text-primary">
          {loading ? (
            <RefreshCw className="w-5 h-5 animate-spin" />
          ) : (
            <RefreshCw className="w-5 h-5" />
          )}
        </button>
      </div>
      <div className="flex flex-col mt-4 space-y-4">
        {transactions.map((t: any) => {
          return (
            <div key={t.id} className="flex w-full items-center">
              <div
                className="rounded-md relative flex-shrink-0 w-10 h-10 border bg-center bg-cover mr-2"
                style={{
                  backgroundImage: `url(${t.photo})`,
                }}>
                {!t.active && (
                  <div
                    style={{ fontSize: 9 }}
                    className="text-white text-xs font-bold flex items-center justify-center absolute left-1/2 top-1/2 -m-2 w-4 h-4 rounded-full bg-gray-400 ring-2 ring-white">
                    <X className="w-3 h-3" />
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-bold">{t.title}</p>
                <p className="text-xs text-secondary font-bold">
                  {num_word(t.amount, ['купон', 'купона', 'купонов'])}
                </p>
              </div>
              <div className="flex flex-col ml-auto items-end">
                <p className="text-sm font-bold text-green-500">{t.count}</p>
                <p className="text-xs text-gray-400">#{t.id}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedShopStats = memo(ShopStats);
export { MemoizedShopStats as ShopStats };
