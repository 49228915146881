import React, { memo, useEffect, useState } from 'react';

import logo from '../../shared/assets/logo.svg';
import qr from '../../shared/assets/qr.svg';

import { useStore } from '../../shared/repository/store';
import { Edit2 } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { QRCodePopup } from '../../features/QrCodePopup';

import { NavBar } from '../../shared/components/Navbar';
import { num_word } from '../../shared/helpers/num_words';
import { TODAY } from '../../shared/constants';
import { ONE_TIME_TRANSACTIONS } from '../Operator';

export type ShopItem = {
  id: number;
  title: string;
  amount: number;
  photo: string;
};

function Shop() {
  const { client, user } = useStore();
  const [showQR, setShowQR] = useState(false);

  const [shopItems, setShopItems] = useState<ShopItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      setLoading(true);
      const { data } = await client.from('shop').select();

      if (data) {
        setShopItems(data.filter((item) => item.active));
      }
      setLoading(false);
    };

    syncer();
  }, []);

  if (!user) {
    return null;
  }

  const userName = user.name.split(' ')[0];

  const balance = (user.transactions ?? [])
    .filter((t) => t.day === TODAY || ONE_TIME_TRANSACTIONS.includes(t.type))
    .reduce((acc, item) => acc + item.amount, 0);

  return (
    <>
      <div className="flex flex-col pt-8 min-h-screen">
        <div className="flex justify-between relative">
          <img src={logo} alt="RT Logo" />
        </div>

        <div className="flex flex-1 flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 rounded-t-4xl pb-20">
          <div className="flex items-center">
            <NavLink
              to="/profile"
              style={{
                backgroundImage: `url(${user.photo})`,
              }}
              className="relative flex-shrink-0 w-16 h-16 bg-gray-100 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center">
              <div className="absolute right-0 bottom-0 w-5 h-5 rounded-full bg-primary text-white flex items-center justify-center">
                <Edit2 className="w-2.5 h-2.5" />
              </div>
            </NavLink>
            <div className="flex flex-col ml-3 mr-2">
              <p className="text-base font-medium">
                Добрый день,{' '}
                <span className="whitespace-nowrap">{userName}</span>!
              </p>
              <p className="text-xl text-secondary font-bold">
                {num_word(balance, ['купон', 'купона', 'купонов'])}
              </p>
            </div>
            <button
              onClick={() => setShowQR(true)}
              className="w-10 h-10 rounded-lg bg-primary ml-auto flex items-center justify-center">
              <img src={qr} alt="QR Code" />
            </button>
          </div>

          {loading && (
            <div className="mt-8 mx-auto w-6 h-6 rounded-full border-2 border-secondary border-t-transparent animate-spin" />
          )}
          <div className="mt-8 grid grid-cols-2 gap-4">
            {shopItems.map((item) => (
              <div
                key={item.id}
                className="rounded-md bg-gray-100 p-2 flex flex-col items-center">
                <img
                  src={item.photo}
                  alt={item.title}
                  className="w-full aspect-square rounded-md"
                />
                <p className="text-center text-base font-bold mt-2">
                  {item.title}
                </p>
                {/* <p className="text-center text-sm text-secondary">
                  {num_word(item.amount, ['купон', 'купона', 'купонов'])}
                </p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <NavBar />

      {showQR && (
        <QRCodePopup
          value={user.number_id!}
          onClose={() => setShowQR(false)}
          role={user.role}
        />
      )}
    </>
  );
}

const MemoizedShop = memo(Shop);
export { MemoizedShop as Shop };
