import React from 'react';

import { Operators } from './ui/Operators';
import { ShopStats } from './ui/ShopStats';
import { Transactions } from './ui/Transactions';

function Dashboard() {
  return (
    <div className="fixed left-0 top-0 right-0 bottom-0 overflow-auto">
      <div className="container mx-auto px-6 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Operators />
          <Transactions />
          <ShopStats />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
