import React, { memo, useEffect, useState } from 'react';

import Compress from 'react-image-file-resizer';
import { Button, Input } from '../../shared/components';
import { useStore } from '../../shared/repository/store';
import { Camera, Edit2 } from 'react-feather';
import { customAlphabet, nanoid } from 'nanoid';
import { padStart, random } from 'lodash';

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function Register() {
  const { client, user } = useStore();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [numberId, setNumberId] = useState('');
  const [email, setEmail] = useState('');
  const [photo, setPhoto] = useState(
    `/rtk_avatars/rtk_${padStart(random(1, 34).toString(), 2, '0')}.png`
  );
  const [imageFile, setImageFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const signUp = async () => {
    setLoading(true);
    const login = customAlphabet('124567890QWRYUSDFGJLN', 6)();
    const password = customAlphabet('124567890QWRYUSDFGJLN', 6)();
    await client.auth.signUp(
      {
        email: `${login}@pitcha.cloud`,
        password,
      },
      { redirectTo: `${window.location.origin}/register` }
    );
    setLoading(false);
  };

  const onImageChange = async (e: any) => {
    const file = e.target.files[0];

    // Compress file on client side
    Compress.imageFileResizer(
      file,
      120,
      120,
      'PNG',
      90,
      0,
      (uri: any) => {
        setImageFile(uri);
      },
      'blob'
    );

    // Update visual file
    const reader = new FileReader();
    reader.onload = (event: any) => {
      setPhoto(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const saveUser = async () => {
    if (!user) {
      return;
    }
    setLoading(true);

    // Detect that user is not already registered
    const { data: existedUser } = await client
      .from('users')
      .select('id')
      .eq('number_id', numberId)
      .maybeSingle();

    if (existedUser) {
      alert('Этот номер уже зарегистрирован');
      setLoading(false);
      return;
    }

    let newPhoto = photo;
    // Update photo
    if (imageFile) {
      const newPhotoPath = `${user.id}/${nanoid(7)}.png`;

      await client.storage.from('avatars').upload(newPhotoPath, imageFile, {
        cacheControl: '3600',
        upsert: true,
      });

      const { publicURL } = client.storage
        .from('avatars')
        .getPublicUrl(newPhotoPath);
      newPhoto = publicURL!;
    }
    const userEntity = {
      id: user.id,
      name,
      email,
      phone,
      number_id: parseInt(numberId),
      photo: newPhoto,
      created_at: user.created_at ?? new Date(),
      last_interacted_at: new Date(),
      active: true,
    };

    const { data } = await client.from('users').upsert(userEntity);
    setLoading(false);
    if (data) {
      alert('Регистрация прошла успешно');
      signOut();
    }
  };

  const signOut = async () => {
    await client.auth.signOut();
    window.location.reload();
  };

  const canSave =
    name.length > 0 &&
    ((email.length > 0 && validateEmail(email)) || phone.length > 0) &&
    parseInt(numberId) > 0 &&
    parseInt(numberId) <= 500;

  if (!user || user.email) {
    return (
      <div className="flex flex-col min-h-screen items-center justify-center">
        {user && user.email.length > 0 ? (
          <>
            <p className="text-xl text-white font-semibold text-center mb-6">
              Необходимо выйти из своего профиля перед регистрацией пользователя
              по бейджу
            </p>
            <Button
              loading={loading}
              title="Выйти из профиля"
              onPress={signOut}
            />
          </>
        ) : (
          <Button loading={loading} title="Новая анкета" onPress={signUp} />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen pt-8">
      <div className="relative flex flex-1 flex-col bg-white mt-20 w-screen max-w-md -mx-6 p-6 pt-0 rounded-t-4xl">
        {/* <button
          onClick={signOut}
          title="Выйти"
          className="-top-10 right-8 absolute p-2 -m-2">
          <LogOut className="text-primary w-5" />
        </button> */}
        <label
          style={{
            backgroundImage: `url(${photo})`,
          }}
          className="relative w-32 h-32 bg-gray-100 -mt-16 mx-auto mb-6 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center">
          {!photo && <Camera className="text-gray-500 w-8 h-8" />}
          <input
            type="file"
            onChange={onImageChange}
            className="hidden"
            accept="image/*"
          />
          <div className="absolute right-1 bottom-1 w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center p-2">
            <Edit2 />
          </div>
        </label>

        <Input
          name="name"
          title="Имя и фамилия"
          value={name}
          onChange={(newName) =>
            setName(newName.replace(/[^а-яА-ЯёЁa-zA-Z\s]/g, '').slice(0, 50))
          }
        />

        <Input
          name="id"
          title="Номер с бейджа"
          value={numberId}
          onChange={(newNumber) =>
            setNumberId(newNumber.replace(/[^0-9]/g, '').slice(0, 50))
          }
        />
        <Input
          name="email"
          title="Ваш e-mail"
          value={email}
          onChange={setEmail}
        />
        <Input
          name="phone"
          title="Телефон"
          value={phone}
          onChange={(newPhone) =>
            setPhone(newPhone.replace(/[^0-9]/g, '').slice(0, 50))
          }
        />
        <div className="my-auto"></div>

        <Button
          loading={loading}
          disabled={!canSave}
          title="Зарегистрировать"
          onPress={saveUser}
        />
        <p className="text-center mt-2 text-sm text-gray-500">{user?.id}</p>
      </div>
    </div>
  );
}

const MemoizedRegister = memo(Register);
export { MemoizedRegister as Register };
