import React, { memo } from 'react';
import { Check } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { TODAY } from '../../../shared/constants';
import { num_word } from '../../../shared/helpers/num_words';
import { Transaction } from '../../../shared/repository/models/Transactions';

import google from './googleplay.svg';
import apple from './appstore.svg';

// import quiz from './quiz.png';
import truth from './truth.png';
// import match from './match.png';
import wink from './wink.png';
import photo from './photo.png';
import tg from './tg.png';
import vk from './vk.png';
import bonus from './bonus.png';
import happy from './happy.png';
import { ONE_TIME_TRANSACTIONS } from '../../Operator';

export const POINTS_DESCRIPTORS = [
  {
    id: 'quiz',
    photo: truth,
    title: 'Викторина',
    text: 'Ответьте на вопросы викторины',
    min: 0,
    max: 3,
    days: [4, 5],
  },
  // {
  //   id: 'truth',
  //   photo: quiz,
  //   title: 'Правда или ложь?',
  //   text: 'Ответьте правильно на вопросы викторины и получите баллы',
  //   min: 0,
  //   max: 6,
  //   days: [4, 5],
  // },
  // {
  //   id: 'match',
  //   photo: match,
  //   title: 'Найди пару',
  //   text: 'Сопоставьте правильно объекты и получите баллы',
  //   min: 0,
  //   max: 6,
  //   days: [4, 5],
  // },
  {
    id: 'photo',
    photo: photo,
    title: 'Опубликуйте фото',
    text: 'Опубликуйте фото с бренд-зоны Wink в соцсетях с хэштегом #явыбираюwink',
    min: 1,
    max: 1,
    days: [4, 5],
  },
  {
    id: 'mc',
    photo: happy,
    title: 'Участвуйте в конкурсах',
    text: 'За активности на стенде можно получить до 5 купонов в день',
    min: 1,
    max: 5,
    days: [4, 5],
  },
  {
    id: 'wink',
    photo: wink,
    title: 'Установите Wink',
    text: 'Установите приложение Wink и введите промокод на бесплатный тестовый период подписки «Трансформер».\n\nНаслаждайтесь 90 дней фильмами, мультфильмами, сериалами и ТВ-каналами, которые можно собрать в одну подписку по своим интересам.',
    min: 1,
    max: 1,
    days: [4, 5],
  },
  {
    id: 'bonus',
    photo: bonus,
    title: 'Программа лояльности Бонус',
    text: 'Зарегистрируйтесь в программе лояльности «Бонус» в личном кабинете абонента Ростелеком. Участвуйте в программе «Бонус» и выбирайте лучшее для себя и близких.',
    min: 1,
    max: 1,
    days: [4, 5],
    url: 'https://spb.rt.ru/bonus',
    url_label: 'Зарегистрироваться',
  },
  {
    id: 'vk',
    photo: vk,
    title: 'Подпишитесь на нашу группу',
    text: 'Подпишитесь на сообщество Ростелекома во ВКонтакте',
    min: 1,
    max: 1,
    days: [4, 5],
    url: 'https://vk.com/rostelecom',
    url_label: 'Подписаться',
  },
  {
    id: 'tg',
    photo: tg,
    title: 'Подпишитесь на наш канал',
    text: 'Подпишитесь на канал Ростелекома в Telegram',
    min: 1,
    max: 1,
    days: [4, 5],
    url: 'https://t.me/rostelecomofficial',
    url_label: 'Подписаться',
  },
];

function GetPoints({
  day,
  transactions,
}: {
  day: number;
  transactions: Transaction[];
}) {
  return (
    <div className="flex flex-col mt-6">
      <h3 className="text-xl font-bold">Получайте купоны</h3>
      <div className="flex flex-col mt-4 space-y-3">
        {POINTS_DESCRIPTORS.map((descriptor) => {
          const currentTransaction = transactions.find(
            (t) =>
              t.type === descriptor.id &&
              (t.day === day || ONE_TIME_TRANSACTIONS.includes(t.type))
          );
          return (
            <div
              key={descriptor.id}
              className="w-full rounded-md border flex flex-col">
              <div className="w-full flex p-4">
                <button
                  onClick={() => {
                    if (descriptor.url) {
                      window.open(descriptor.url, '_blank');
                    }
                  }}
                  className="relative w-16 h-16 rounded-full bg-gray-200 mr-4 flex-shrink-0">
                  <img
                    src={descriptor.photo}
                    alt="icon"
                    className="w-full h-full absolute rounded-full border left-0 top-0"
                  />
                  {currentTransaction && (
                    <div className="absolute right-0 bottom-0 w-5 h-5 rounded-full bg-green-500 text-white flex items-center justify-center">
                      <Check className="w-3 h-3" />
                    </div>
                  )}
                </button>
                <div className="flex flex-col">
                  <h4 className="text-lg font-bold">{descriptor.title}</h4>
                  <p
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                    className="text-sm">
                    {descriptor.text}
                  </p>
                  {currentTransaction ? (
                    <p className="text-xl font-bold text-green-500 mt-1">
                      {num_word(currentTransaction.amount, [
                        'купон',
                        'купона',
                        'купонов',
                      ])}
                    </p>
                  ) : (
                    <p className="text-xl font-bold text-secondary mt-1">
                      {descriptor.min === descriptor.max
                        ? num_word(descriptor.max, [
                            'купон',
                            'купона',
                            'купонов',
                          ])
                        : `До ${num_word(descriptor.max, [
                            'купон',
                            'купонов',
                            'купонов',
                          ])}`}
                    </p>
                  )}
                  {descriptor.url && !currentTransaction && (
                    <a
                      href={descriptor.url}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-primary rounded-md text-white flex items-center justify-center text-base w-full h-10 mt-3">
                      {descriptor.url_label}
                    </a>
                  )}
                  {['quiz', 'truth', 'match'].includes(descriptor.id) &&
                    TODAY === day &&
                    !currentTransaction && (
                      <NavLink
                        to={`/${descriptor.id}`}
                        className="bg-primary rounded-md text-white flex items-center justify-center text-base w-full h-10 mt-3">
                        Участвовать
                      </NavLink>
                    )}

                  {descriptor.id === 'wink' && !currentTransaction && (
                    <div className="flex flex-col mt-4">
                      <a
                        style={{
                          width: 149,
                          height: 44,
                        }}
                        href="https://go.onelink.me/app/winkandroid"
                        target="_blank"
                        rel="noreferrer"
                        className="bg-gray-900 rounded-xl flex items-center justify-center mb-2">
                        <img src={google} alt="Download from Google Play" />
                      </a>
                      <a
                        style={{
                          width: 149,
                          height: 44,
                        }}
                        href="https://apps.apple.com/RU/app/id1294366633"
                        target="_blank"
                        rel="noreferrer"
                        className="bg-gray-900 rounded-xl flex items-center justify-center">
                        <img src={apple} alt="Download from Appstore" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {descriptor.id === 'wink' && !currentTransaction && (
                <p className="text-xs bg-gray-100 p-2 px-4">
                  * промо-код можно получить на бренд-зоне Ростелекома
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedGetPoints = memo(GetPoints);
export { MemoizedGetPoints as GetPoints };
