import React, { memo, useEffect, useState } from 'react';
import { Gift, RefreshCw } from 'react-feather';
import { Button } from '../../shared/components';
import { num_word } from '../../shared/helpers/num_words';
import { User } from '../../shared/repository/models/User';
import { useStore } from '../../shared/repository/store';
import { padStart, sample } from 'lodash';
import { LOTTERY_NUMBER } from '../Lottery';
import { Transaction } from '../../shared/repository/models/Transactions';
import { TODAY } from '../../shared/constants';
import { ONE_TIME_TRANSACTIONS } from '../Operator';

type Coupon = {
  id: number;
  transaction_id: number;
  type: string;
  user: string;
};

const DESCRIPTORS: Record<string, string> = {
  quiz: 'прохождение квиза',
  match: 'прохождение квиза',
  truth: 'прохождение квиза',
  photo: 'публикацию фото',
  wink: 'установку Wink',
  mc: 'активности на стенде',
  bonus: 'регистрацию в личном кабинете',
  vk: 'подписку в VK',
  tg: 'подписку в Telegram',
};

function LotteryCheck() {
  const { client } = useStore();
  const [loading, setLoading] = useState(false);
  const [choosedWinner, chooseWinner] = useState<User | null>(null);
  const [choosedWinnerCoupon, setChoosedWinnerCoupon] = useState<Coupon | null>(
    null
  );

  const [refresh, setRefresh] = useState({});
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [winnerWasSet, setWinnerWasSet] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      const { data } = await client
        .from('transactions')
        .select('id, created_for, day, amount, type, lottery_winner');

      const { data: winners } = await client
        .from('winners')
        .select('winner_number');

      const winnersNumbers = (winners ?? []).map((w) =>
        parseInt(w.winner_number)
      );

      const newCoupons: Coupon[] = [];

      const transactions =
        data?.filter(
          (t) => t.day === TODAY // || ONE_TIME_TRANSACTIONS.includes(t.type)
        ) ?? [];

      // TODO: Add filter for day and type
      transactions.forEach((transaction: Transaction) => {
        for (
          let couponIndex = 0;
          couponIndex < transaction.amount;
          couponIndex++
        ) {
          const newCoupon: Coupon = {
            id: transaction.id! * LOTTERY_NUMBER * 100 + couponIndex,
            type: transaction.type,
            user: transaction.created_for,
            transaction_id: transaction.id!,
          };

          if (!winnersNumbers.includes(newCoupon.id)) {
            newCoupons.push(newCoupon);
          }
        }
      });
      setCoupons(newCoupons);
    };
    syncer();
  }, [refresh]);

  const generateWinner = async () => {
    setWinnerWasSet(false);
    setLoading(true);
    chooseWinner(null);
    setChoosedWinnerCoupon(null);

    const randomCoupon = sample(coupons)!;

    const { data } = await client
      .from('users')
      .select('*')
      .eq('id', randomCoupon.user)
      .single();

    if (data !== null) {
      const winner = data;
      await client
        .from('lottery')
        .update({ winner: winner.id, coupon: randomCoupon })
        .eq('id', 1);
      chooseWinner(winner);
      setChoosedWinnerCoupon(randomCoupon);
    }

    setLoading(false);
  };

  const setWinner = async () => {
    if (choosedWinner === null || choosedWinnerCoupon === null) {
      return;
    }

    setLoading(true);

    await client.from('winners').insert({
      winner_number: choosedWinnerCoupon.id,
    });

    await client
      .from('lottery')
      .update({ winner: null, coupon: null })
      .eq('id', 1);

    setRefresh({});
    setWinnerWasSet(true);
    setLoading(false);
  };

  const onRefresh = async () => {
    setLoading(true);
    await client
      .from('lottery')
      .update({ winner: null, coupon: null })
      .eq('id', 1);
    setChoosedWinnerCoupon(null);
    chooseWinner(null);
    setWinnerWasSet(false);
    setLoading(false);
  };

  const canSetGift = choosedWinner && !loading;

  const userCoupons = coupons.filter(
    (c) => c.user === choosedWinner?.id
  ).length;

  return (
    <>
      <div className="fixed left-0 top-0 right-0 bottom-0 bg-secondary"></div>
      <div className="bg-white fixed left-0 right-0 bottom-0 h-20"></div>

      <div className="relative flex flex-col min-h-screen pt-6">
        <div className="flex-1 flex flex-col items-center justify-center">
          {choosedWinner && (
            <div className="flex flex-col items-center w-full mb-8 text-white mt-auto">
              <div className="flex w-full">
                <div
                  style={{
                    backgroundImage: `url(${choosedWinner.photo})`,
                  }}
                  className="relative flex-shrink-0 w-20 h-20 bg-gray-100 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center"></div>
                <div className="flex flex-col ml-4 justify-center">
                  <p className="text-xl font-bold">{choosedWinner.name}</p>
                  <p className="text-white tracking-wider text-base">
                    <span className="font-lottery">
                      #
                      {choosedWinner.number_id! < 501
                        ? '1'
                        : choosedWinner.role === 'member'
                        ? 'A'
                        : 'B'}
                      {padStart(
                        choosedWinner.number_id!.toString(),
                        choosedWinner.number_id! < 501 ? 4 : 5,
                        '0'
                      )}
                    </span>{' '}
                    — {num_word(userCoupons, ['купон', 'купона', 'купонов'])}
                  </p>
                </div>
              </div>
            </div>
          )}

          {choosedWinnerCoupon && (
            <div className="relative w-full flex rounded-lg bg-warm p-4 px-7 mb-4">
              <div className="absolute w-6 h-6 rounded-full bg-secondary -left-3 top-1/2 -mt-3"></div>
              <div className="absolute w-6 h-6 rounded-full bg-secondary -right-3 top-1/2 -mt-3"></div>

              <div className="w-full flex flex-col">
                <p className="text-xl font-semibold font-lottery mb-0.5">
                  #{choosedWinnerCoupon.id}
                </p>
                <p className="text-sm text-black/50">
                  Купон за{' '}
                  {DESCRIPTORS[choosedWinnerCoupon.type] ?? 'активность'}
                </p>
              </div>
            </div>
          )}

          {winnerWasSet && (
            <p className="text-xl font-semibold mb-4 text-center text-white">
              Подарок выдан
            </p>
          )}

          <p className="text-white text-center text-xl font-semibold px-6 mt-auto">
            {num_word(coupons.length, ['купон', 'купона', 'купонов'])} в
            розыгрыше
          </p>
          <button
            onClick={() => setRefresh({})}
            className="text-white w-8 h-8 mt-4 items-center justify-center flex">
            <RefreshCw />
          </button>
        </div>

        <div className="relative flex flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 pt-6 rounded-t-4xl">
          <button
            onClick={onRefresh}
            className="w-full text-primary text-base font-semibold mb-4">
            Сбросить текущего
          </button>
          <div className="flex space-x-2">
            <Button
              loading={loading}
              title="Сгенерировать"
              onPress={generateWinner}
            />
            <button
              onClick={setWinner}
              disabled={!canSetGift}
              className={`${
                canSetGift ? '' : 'poiner-events-none opacity-50'
              } flex-shrink-0 rounded-md bg-primary w-16 h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center`}>
              <Gift />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedLotteryCheck = memo(LotteryCheck);
export { MemoizedLotteryCheck as LotteryCheck };
