export const QUIZ_QUESTIONS = [
  {
    id: 1,
    photo: '/quiz/1.jpg',
    question:
      'Фильм “Легенда №17” посвящён знаменитой хоккейной суперсерии 1972 года. Против команды какой страны тогда играла сборная СССР?',
    answers: ['США', 'Канада', 'Испания', 'Швеция'],
    correct: 1,
  },
  {
    id: 2,
    photo: '/quiz/2.jpg',
    question:
      'В фильме “Легенда №17” рассказывается история о легендарном советском хоккеисте. Вспомните, как его зовут?',
    answers: [
      'Лев Яшин',
      'Андрей Аршавин',
      'Валерий Харламов',
      'Данила Козловский',
    ],
    correct: 2,
  },
  {
    id: 3,
    photo: '/quiz/3.jpg',
    question:
      'У футболиста Льва Яшина, главного героя одноименного фильма, было необычное прозвище. Вспомните, какое?',
    answers: [
      'Чёрный паук',
      'Белая каракатица',
      'Синий таракан',
      'Красный бык',
    ],
    correct: 0,
  },
  {
    id: 4,
    photo: '/quiz/4.jpg',
    question:
      'Против команды из какой страны играла сборная СССР по баскетболу в финале фильма “Движение вверх”?',
    answers: ['Канада', 'Новая Зеландия', 'Англия', 'США'],
    correct: 3,
  },
  {
    id: 5,
    photo: '/quiz/5.jpg',
    question:
      'В финале фильма “Движение вверх” сборная СССР по баскетболу выступала на XX олимпийских играх. А в каком городе они проводились?',
    answers: ['Лондон', 'Мюнхен', 'Лос-Анджелес', 'Москва'],
    correct: 1,
  },
  {
    id: 6,
    photo: '/quiz/6.jpg',
    question:
      'Фильм “Ford против Ferrari” повествует о противостоянии великих автоконструкторов и гонщиков. А на какой трассе проходил финал этой истории?',
    answers: ['Ле-Ман', 'Дейтона', 'Индианаполис', 'Монте-Карло'],
    correct: 0,
  },
  {
    id: 7,
    photo: '/quiz/7.jpg',
    question:
      'В фильме “Ford против Ferrari” Генри Форд II захотел построить автомобиль, способный победить Ferrari в Ле-Мане. Как впоследствии назвали этот легендарный автомобиль?',
    answers: ['Ford Focus', 'Ford Mondeo', 'Ford GT40', 'Ford F-150'],
    correct: 2,
  },
  {
    id: 8,
    photo: '/quiz/8.jpg',
    question:
      'Фильм “Гонка” рассказывает историю противостояния двух легендарных гонщиков Формулы 1: Ники Лауды и Джеймса Ханта. Вспомните, за какую команду выступал Ники Лауда в этом фильме?',
    answers: [
      'Scuderia Ferrari',
      'Marlboro McLaren',
      'Hesketh Racing',
      'Martini Racing',
    ],
    correct: 0,
  },
  {
    id: 9,
    photo: '/quiz/9.jpg',
    question:
      'Фильм “Гонка” рассказывает историю противостояния двух легендарных гонщиков Формулы 1: Ники Лауды и Джеймса Ханта. Вспомните, за какую команду выступал Джеймс Хант в этом фильме?',
    answers: [
      'Scuderia Ferrari',
      'Marlboro McLaren',
      'Hesketh Racing',
      'Martini Racing',
    ],
    correct: 1,
  },
  {
    id: 10,
    photo: '/quiz/10.jpg',
    question:
      'В фильме “Движение вверх” создатели изменили фамилию тренера сборной СССР по баскетболу (в фильме его звали Владимир Гаранжин). Как на самом деле звали легендарного тренера?',
    answers: [
      'Владимир Машков',
      'Владимир Высоцкий',
      'Владимир Познер',
      'Владимир Кондрашин',
    ],
    correct: 3,
  },
  {
    id: 11,
    photo: '/quiz/11.jpg',
    question:
      'Футболист Лев Яшин, герой одноимённого фильма, пропустил гол в первом же матче в основном составе «Динамо». А против какой команды тогда играл «Динамо»?',
    answers: ['Барселона', 'Зенит', 'Спартак', 'Торпедо'],
    correct: 2,
  },
  {
    id: 12,
    photo: '/quiz/12.jpg',
    question:
      'В фильме “Ford против Ferrari” Генри Форд II жаждал победить Ferrari в гонке Ле-Ман. В каком же году команда Ford наконец одержала победу?',
    answers: ['1970', '1956', '1988', '1966'],
    correct: 3,
  },
  {
    id: 13,
    photo: '/quiz/13.jpg',
    question:
      'В финале фильма “Легенда №17” сборная СССР побеждает сборную Канады с разгромным счётом. Вспомните, сколько шайб забила сборная СССР в том знаменательном матче?',
    answers: ['5', '7', '10', '30'],
    correct: 1,
  },
  {
    id: 14,
    photo: '/quiz/14.jpg',
    question:
      'Ниже представлено несколько интересных фактов о фильме “Легенда №17”, но только один из них правдивый. Отгадаете, какой?',
    answers: [
      'Данила Козловский снимался в настоящей куртке Валерия Харламова',
      'Все клюшки на съёмочной площадке были сделаны из пенопласта',
      'Данила Козловский не умел кататься на коньках, и на льду вместо него снимался дублёр',
      'В Канаде фильм “Легенда №17” официально запрещён',
    ],
    correct: 0,
  },
  {
    id: 15,
    photo: '/quiz/15.jpg',
    question:
      'Ниже представлено несколько интересных фактов о фильме “Лев Яшин. Вратарь моей мечты”, но только один из них правдивый. Отгадаете, какой?',
    answers: [
      'Мячи для съёмок фильма шили из специальной кожи буйволов для большей достоверности',
      'В съёмках фильма принимал участие внук Льва Яшина',
      'Лев Яшин на самом деле не пропустил ни одного мяча за всю свою карьеру',
      'Актёр Александр Фокин, сыгравший главную роль, на самом деле не любит футбол',
    ],
    correct: 1,
  },
  {
    id: 16,
    photo: '/quiz/16.jpg',
    question:
      'Ниже представлено несколько интересных фактов о фильме “Ford против Ferrari”, но только один из них правдивый. Отгадаете, какой?',
    answers: [
      'Во время съёмок фильма было потрачено 8000 литров бензина',
      'На самом деле Кристиан Бейл не умеет водить',
      '“Ford против Ferrari” показывают в автошколах Австралии для обучения вождению',
      'Для съёмок в этом фильме Кристиан Бейл похудел на почти 30 кг',
    ],
    correct: 3,
  },
  {
    id: 17,
    photo: '/quiz/17.jpg',
    question:
      'Ниже представлено несколько интересных фактов о фильме “Гонка”, но только один из них правдивый. Отгадаете, какой?',
    answers: [
      'Оба актёра, сыгравшие главные роли в фильме, на самом деле не умеют водить',
      'Крис Хемсворт во время съёмок фильма случайно поставил мировой рекорд на трассе Нюрбургринг',
      'Вместо машин Формулы 1, актёры ездили на машинах Формулы 3 с кузовами от Формулы 1',
      'Актёры вылили 35 бутылок шампанского во время съёмки сцены на подиуме',
    ],
    correct: 2,
  },
  {
    id: 18,
    photo: '/quiz/18.jpg',
    question:
      'Ниже представлено несколько интересных фактов о фильме “Движение вверх”, но только один из них правдивый. Отгадаете, какой?',
    answers: [
      'Все баскетбольные мячи в фильме на самом деле являются покрашенными футбольными мячами',
      'Для большей достоверности обучать актёров баскетболу взялся Леброн Джеймс',
      'Во время съёмок лопнуло 250 мячей',
      'Чтобы выглядеть в кадре выше, актёры, играющие баскетболистов, носили обувь на высокой платформе',
    ],
    correct: 3,
  },
];
