import React, { memo } from 'react';
import { customAlphabet } from 'nanoid';

import logo from '../../shared/assets/logo.svg';
import google from '../../shared/assets/google.svg';
// import apple from '../../shared/assets/apple.svg';
import kvImage from '../../shared/assets/kv.svg';

import { Button } from '../../shared/components';
import { useStore } from '../../shared/repository/store';
import { POLICY_URL } from '../../shared/constants';

function Auth() {
  const { client } = useStore();

  async function signInAsGuest() {
    // Trying login by prev creds
    const currentCreds = localStorage.getItem('login_credentials');
    if (currentCreds) {
      const [existedLogin, existedPassword] = currentCreds.split(':');
      const { user } = await client.auth.signIn(
        {
          email: `${existedLogin}@pitcha.cloud`,
          password: existedPassword,
        },
        {
          redirectTo: `${window.location.origin}`,
        }
      );
      if (user) {
        window.location.href = '/points';
        return;
      }
    }

    const login = customAlphabet('124567890QWRYUSDFGJLN', 6)();
    const password = customAlphabet('124567890QWRYUSDFGJLN', 6)();
    localStorage.setItem('login_credentials', `${login}:${password}`);
    await client.auth.signUp(
      {
        email: `${login}@pitcha.cloud`,
        password,
      },
      { redirectTo: `${window.location.origin}/profile` }
    );
  }

  async function signInWithGoogle() {
    await client.auth.signIn(
      { provider: 'google' },
      { redirectTo: `${window.location.origin}/profile` }
    );
  }

  // async function signInWithApple() {
  //   await client.auth.signIn(
  //     { provider: 'apple' },
  //     { redirectTo: `${window.location.origin}/profile` }
  //   );
  // }

  const showGoogleLogin = window.location.hash === '#google';

  return (
    <div className="flex flex-col pb-12 min-h-screen">
      <div className="flex relative bg-white">
        <div className="absolute bottom-0 left-1/2 w-screen h-full bg-white transform -translate-x-1/2" />
        <img
          src={kvImage}
          alt="Key Visual"
          className="relative mx-auto w-full mt-8"
        />
      </div>
      <div className="flex justify-between relative mt-auto">
        <img src={logo} alt="RT Logo" className="w-32 mb-8 mt-8" />
      </div>
      <h1 className="text-2xl font-medium mb-6 text-white">
        Зарабатывайте купоны и участвуйте в розыгрыше призов от Wink на стенде
        Ростелеком
      </h1>
      <Button title="Подключиться" onPress={signInAsGuest} />
      {showGoogleLogin && (
        <>
          <div className="my-4 flex w-full items-center space-x-2">
            <div className="w-full border-t border-white/30"></div>
            <p className="flex-shrink-0 text-sm text-center text-gray-500">
              или
            </p>
            <div className="w-full border-t border-white/30"></div>
          </div>
          <div className="flex space-x-4 items-center justify-center w-full mb-4">
            <button
              onClick={signInWithGoogle}
              className="font-medium shadow-xl w-full h-14 rounded-md bg-white flex items-center justify-center p-2">
              <img
                src={google}
                className="w-7 mr-3 -mt-0.5"
                alt="google icon"
              />
              Войти через Google
            </button>
            {/* <button
          onClick={signInWithApple}
          className="shadow-xl w-full h-14 rounded-md bg-white flex items-center justify-center p-2">
          <img src={apple} className="w-10" alt="apple icon" />
        </button> */}
          </div>
        </>
      )}

      <a
        rel="noreferrer"
        href={POLICY_URL}
        target="_blank"
        className="text-center mt-2 text-sm text-gray-500 underline">
        Политика конфиденциальности
      </a>
    </div>
  );
}

const MemoizedAuth = memo(Auth);
export { MemoizedAuth as Auth };
