import React, { memo, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './assets/question.json';
import confettiJson from './assets/confetti.json';

import logo from '../../shared/assets/logo.svg';

import { useStore } from '../../shared/repository/store';
import { num_word } from '../../shared/helpers/num_words';

import { QUIZ_QUESTIONS } from './config';
import { Button } from '../../shared/components';
import { padStart, sampleSize, shuffle } from 'lodash';
import { TODAY } from '../../shared/constants';
import { Transaction } from '../../shared/repository/models/Transactions';

const NEED_TO_ANSWER = 3;
const SECONDS_TO_ANSWER = 30;

const RESULTS_DESCRIPTORS = {
  0: 'Увы, так тоже бывает. Не расстраивайтесь, ведь купоны можно получить и другими способами!',
  1: 'Могло быть и хуже! Один купон тоже хорошо 😉',
  2: 'Два купона тут, два купона там, вот и приз в кармане!',
  // 3: 'Половина правильных, неплохой результат!',
  // 4: 'Еще бы парочку, и получили бы максимум. А так, твердая четверка!',
  // 5: 'Отличный результат! Пять из шести!',
  3: 'Ну вы даете, это же максимальный результат из возможных!',
} as any;

function Quiz() {
  const { client, user } = useStore();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);
  const [answers, setAnswers] = useState<any[]>([]);
  const [remainTime, setRemainTime] = useState<number | null>(null);
  const [choosedAnswer, chooseAnswer] = useState<string | null>(null);
  // Visual effects
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      const { data } = await client
        .from('transactions')
        .select('id')
        .eq('unique_id', `${user!.id}_quiz_${TODAY}`)
        .single();
      if (data) {
        window.location.href = '/points';
      }
    };
    const prepareQuestions = async () => {
      // Load my responses
      const { data: prevPlays } = await client
        .from('transactions')
        .select('quiz_info')
        .eq('created_by', user!.id)
        .eq('type', 'quiz');

      const showedQuestions: number[] = [];

      (prevPlays ?? []).map((item: any) => {
        item.quiz_info.forEach((q: any) => {
          showedQuestions.push(parseInt(q.id));
        });
      });

      const filteredQuestions = QUIZ_QUESTIONS.filter(
        (q) => !showedQuestions.includes(q.id)
      );
      setQuestions(sampleSize(filteredQuestions, NEED_TO_ANSWER));
    };

    syncer();
    prepareQuestions();
  }, [client, user]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (currentQuestion) {
      const hasAnswersForCurrentQuestion = answers.some(
        (a) => a.id === currentQuestion.id
      );
      if (remainTime === null && !hasAnswersForCurrentQuestion)
        setRemainTime(SECONDS_TO_ANSWER);
      timer = setTimeout(() => {
        if (remainTime !== null && remainTime > 0) {
          setRemainTime(remainTime - 1);
        }
      }, 1000);
    } else {
      setRemainTime(null);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [remainTime, currentQuestion, answers]);

  useEffect(() => {
    const syncer = async () => {
      setLoading(true);
      const correctAnswers = answers.filter((a) => a.correct).length;

      const newTransaction: Transaction = {
        created_by: user!.id,
        created_for: user!.id,
        amount: correctAnswers,
        type: 'quiz',
        day: TODAY,
        unique_id: `${user!.id}_quiz_${TODAY}`,
        quiz_info: answers,
      };

      await client.from('transactions').insert(newTransaction);
      setLoading(false);
    };

    if (isFinished) {
      syncer();
    }
  }, [isFinished, client, user]);

  const onCheckAnswer = () => {
    const thisQuestion = QUIZ_QUESTIONS.find(
      (q) => q.id === currentQuestion.id
    )!;
    const correctAnswer = thisQuestion.answers[thisQuestion.correct]!;
    setAnswers([
      ...answers,
      { id: currentQuestion.id, correct: choosedAnswer === correctAnswer },
    ]);
    setRemainTime(null);
  };

  const onNextQuestion = () => {
    chooseAnswer(null);
    const nextQuestion = questions[answers.length];
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
    } else {
      setIsFinished(true);
    }
  };

  const onSkipQuestion = () => {
    setAnswers([...answers, { id: currentQuestion.id, correct: false }]);
    setRemainTime(null);
  };

  if (!user) {
    return null;
  }

  if (currentQuestion) {
    const thisQuestion = QUIZ_QUESTIONS.find(
      (q) => q.id === currentQuestion.id
    )!;
    const correctAnswer = thisQuestion.answers[thisQuestion.correct]!;
    const answerOfThisQuestion = answers.find(
      (a) => a.id === currentQuestion.id
    );
    const shouldHighlightTimer =
      remainTime !== null && remainTime > 0 && remainTime <= 10;
    const timeIsOver = remainTime === 0 && !answerOfThisQuestion;
    const correctAnswers = answers.filter((a) => a.correct).length;
    const currentPositionOfQuestion = !answerOfThisQuestion
      ? Math.min(answers.length + 1, NEED_TO_ANSWER)
      : Math.min(answers.length, NEED_TO_ANSWER);

    return (
      <>
        <div className="fixed left-0 top-0 right-0 bottom-0 bg-secondary"></div>
        <div className="animate-show-opacity relative flex flex-col pt-8 min-h-screen">
          <div className="flex items-center mb-4 bg-secondary fixed left-0 right-0 top-0 p-4 px-6 pb-3">
            <h3 className="text-white text-xl font-bold text-center">
              Вопрос {currentPositionOfQuestion}/{NEED_TO_ANSWER}
            </h3>
            <div
              className={`${
                shouldHighlightTimer ? 'text-red-500' : 'text-secondary'
              } bg-white flex-shrink-0 relative text-sm rounded-xl font-bold w-20 h-8 flex items-center justify-center ml-auto tracking-wider`}>
              00:{padStart((remainTime ?? 0).toString(), 2, '0')}
              {shouldHighlightTimer && (
                <span className="absolute animate-ping h-full rounded-full w-full border border-white"></span>
              )}
            </div>
          </div>
          <img
            src={currentQuestion.photo}
            alt="photo"
            className="w-full rounded-t-xl mt-8"
          />
          <p className="text-base font-medium text-center mb-auto bg-white p-5 rounded-b-xl">
            {currentQuestion.question}
          </p>
          <div className="flex flex-1 flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 rounded-t-4xl pb-8">
            <div className="flex w-full flex-col mb-auto space-y-2 pb-4">
              {currentQuestion.answers.map((answer: string) => (
                <button
                  key={answer}
                  onClick={() => {
                    if (!timeIsOver) {
                      chooseAnswer(answer);
                    }
                  }}
                  className={`${
                    choosedAnswer === answer && !answerOfThisQuestion
                      ? 'border-primary shadow-xl shadow-primary/10'
                      : ''
                  } ${
                    answerOfThisQuestion && correctAnswer === answer
                      ? 'border-green-500 shadow-xl shadow-green-500/10'
                      : ''
                  } ${
                    answerOfThisQuestion &&
                    choosedAnswer === answer &&
                    correctAnswer !== answer
                      ? 'border-red-500'
                      : ''
                  } w-full px-3 py-2 text-base border rounded-lg`}>
                  {answer}
                </button>
              ))}
            </div>
            {(timeIsOver || answerOfThisQuestion) && (
              <p
                className={`text-center mb-2 text-sm ${
                  answerOfThisQuestion?.correct
                    ? 'text-green-500'
                    : 'text-red-500'
                }`}>
                {timeIsOver
                  ? 'Время вышло'
                  : answerOfThisQuestion?.correct
                  ? 'Вы ответили верно!'
                  : 'Увы, ответ неверный'}
              </p>
            )}
            {timeIsOver ? (
              <button
                onClick={() => onSkipQuestion()}
                className={`bg-red-500 rounded-md w-full h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center`}>
                Время вышло
              </button>
            ) : answerOfThisQuestion ? (
              <button
                onClick={() => onNextQuestion()}
                className={`${
                  answerOfThisQuestion.correct ? 'bg-green-500' : 'bg-red-500'
                } rounded-md w-full h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center`}>
                Дальше
              </button>
            ) : (
              <Button
                disabled={choosedAnswer === null}
                title="Ответить"
                onPress={onCheckAnswer}
              />
            )}
          </div>
        </div>
        {isFinished && (
          <div className="p-6 animate-show-opacity fixed left-0 top-0 right-0 bottom-0 bg-bg flex flex-col items-center justify-center">
            <h3 className="text-4xl font-bold relative w-full text-center mt-auto text-white">
              {correctAnswers > 0 && (
                <Lottie
                  className="pointer-events-none absolute w-full aspect-square left-0 top-0 transform -translate-y-1/2 -mt-4"
                  play
                  loop={true}
                  animationData={confettiJson}
                />
              )}
              {correctAnswers} / {NEED_TO_ANSWER}
            </h3>
            <p className="text-sm mt-2 text-center px-4 mb-auto text-white">
              Вы ответили правильно на{' '}
              {num_word(correctAnswers, ['вопрос', 'вопроса', 'вопросов'])} и
              получаете{' '}
              {num_word(correctAnswers, ['купон', 'купона', 'купонов'])}.{' '}
              {RESULTS_DESCRIPTORS[correctAnswers]}
            </p>
            <Button
              loading={loading}
              title={`Получить ${num_word(correctAnswers, [
                'купон',
                'купона',
                'купонов',
              ])}`}
              onPress={() => {
                window.location.href = '/points';
              }}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="bg-white fixed left-0 right-0 bottom-0 h-20 -z-10"></div>
      <div
        className={`${
          isStarted ? 'animate-show-opacity' : 'hidden'
        } fixed left-0 top-0 right-0 bottom-0 bg-secondary pointer-events-none`}></div>
      <div className="flex flex-col pt-8 min-h-screen">
        <div
          className={`${
            isStarted ? 'animate-hide-to-top' : ''
          } flex justify-between relative`}>
          <img src={logo} alt="RT Logo" />
        </div>

        <div
          className={`${
            isStarted ? 'animate-hide-to-bottom' : ''
          } flex flex-1 flex-col bg-white mt-8 w-screen max-w-md -mx-6 p-6 rounded-t-4xl pb-8 items-center justify-center`}>
          <Lottie
            className="mt-auto"
            play
            loop={false}
            animationData={lottieJson}
            style={{ width: 150, height: 150 }}
          />
          <h3 className="text-xl font-bold">Викторина</h3>
          <p className="text-sm mt-2 text-center mb-auto">
            Викторина на тему спорта! 3 вопроса, только один правильный ответ
            в каждом, 30 секунд на ответ. За каждый правильный ответ вы получите
            1 купон. Поехали?
          </p>

          <Button
            title="Поехали!"
            onPress={() => {
              setIsStarted(true);
              setTimeout(() => {
                setCurrentQuestion({
                  ...questions[0],
                  answers: shuffle(questions[0].answers),
                });
              }, 1000);
            }}
          />
        </div>
      </div>
    </>
  );
}

const MemoizedQuiz = memo(Quiz);
export { MemoizedQuiz as Quiz };
