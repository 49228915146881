import React, { memo, useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { Navigate } from 'react-router-dom';
import { log } from '../../../shared/helpers/log';
import { num_word } from '../../../shared/helpers/num_words';
import { User } from '../../../shared/repository/models/User';
import { useStore } from '../../../shared/repository/store';

function Operators() {
  const { user, client } = useStore();
  const [operators, setOperators] = useState<User[]>([]);
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const syncer = async () => {
      setLoading(true);
      const { data } = await client
        .from('users')
        .select()
        .neq('role', 'member');

      if (!data) {
        setLoading(false);
        return;
      }

      for (let index = 0; index < data.length; index++) {
        const operator = data[index];
        const { data: transactions } = await client
          .from('transactions')
          .select()
          .in('type', ['shop', 'photo', 'wink'])
          .eq('created_by', operator.id);

        data[index].transactions = transactions;
      }
      data.sort((a, b) => b.transactions.length - a.transactions.length);
      setOperators(data.filter((a) => a.transactions.length > 0) as User[]);
      setLoading(false);
    };
    syncer();
  }, [refresh]);

  if (user!.role !== 'admin') {
    return <Navigate to="/points" />;
  }

  return (
    <div className="bg-white w-full rounded-xl p-6 flex flex-col">
      <div className="flex w-full justify-between">
        <h3 className="text-xl font-bold">Операторы</h3>
        <button onClick={() => setRefresh({})} className="text-primary">
          {loading ? (
            <RefreshCw className="w-5 h-5 animate-spin" />
          ) : (
            <RefreshCw className="w-5 h-5" />
          )}
        </button>
      </div>
      <div className="flex flex-col mt-4 space-y-4">
        {operators.map((o, index) => {
          const lastTransaction = o.transactions
            ? o.transactions[o.transactions.length - 1]
            : null;
          const lastType = lastTransaction ? lastTransaction.type : '—';
          const lastDate = lastTransaction?.created_at ?? null;
          const lastDateStr = lastDate
            ? new Date(lastDate).toLocaleTimeString()
            : '—';
          return (
            <div key={o.id} className="flex w-full items-center">
              <p className="text-sm w-5 flex-shrink-0">{index + 1}</p>
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full bg-center bg-cover mr-2"
                style={{
                  backgroundImage: `url(${o.photo!})`,
                }}></div>
              <div className="flex flex-col">
                <p className="text-sm font-bold">{o.name}</p>
                <p className="tracking-wider text-xs uppercase text-secondary font-bold">
                  {lastType} - {lastDateStr}
                </p>
              </div>
              <div className="flex flex-col ml-auto items-end">
                <p className="text-sm font-bold">{o.transactions?.length}</p>
                <p className="text-xs text-gray-400">#{o.number_id}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedOperators = memo(Operators);
export { MemoizedOperators as Operators };
