import React, { memo } from 'react';
import { X } from 'react-feather';

function Input({
  title,
  name,
  onChange,
  value,
}: {
  title: string;
  name: string;
  value: string;
  onChange: (newVal: string) => void;
}) {
  const type = name === 'email' ? 'email' : name === 'id' ? 'number' : 'text';
  const showClear = name === 'id' && value.length > 0;

  return (
    <div className="relative w-full rounded-md border border-gray-200 px-4 py-2.5 mb-3">
      <label className="text-gray-500 text-sm">{title}</label>
      {type === 'number' ? (
        <input
          name={name}
          type={type}
          pattern="[0-9]*"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full -mt-1 text-lg text-gray-900 outline-none"
        />
      ) : (
        <input
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full -mt-1 text-lg text-gray-900 outline-none"
        />
      )}
      {showClear && (
        <button
          onClick={() => onChange('')}
          className="absolute right-4 top-1/2 -mt-2">
          <X className="w-5 h-5" />
        </button>
      )}
    </div>
  );
}

const MemoizedInput = memo(Input);
export { MemoizedInput as Input };
