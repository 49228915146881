import { padStart } from 'lodash';
import React, { memo } from 'react';
import QRCode from 'react-qr-code';
import { User } from '../../shared/repository/models/User';

function QRCodePopup({
  value,
  onClose,
  role,
}: {
  value: number;
  onClose: () => void;
  role: User['role'];
}) {
  const link = `https://rostelecom-sport.ru/operator#${value}`;
  const firstDigit = role === 'member' ? 'A' : 'B';

  return (
    <div className="fixed w-full h-full left-0 top-0 flex items-center justify-center">
      <button
        onClick={() => onClose()}
        className="w-full h-full absolute bg-black/50"
      />
      <div className="container mx-auto px-6">
        <div className="aspect-square relative flex flex-col items-center justify-center bg-white rounded-4xl p-6 pt-10">
          <QRCode value={link} />
          <p className="text-3xl font-bold text-center mt-6 text-secondary tracking-wider">
            {firstDigit}
            {padStart(value.toString(), 5, '0')}
          </p>
        </div>
      </div>
    </div>
  );
}

const MemoizedQRCode = memo(QRCodePopup);
export { MemoizedQRCode as QRCodePopup };
