import { padStart } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { User } from '../../shared/repository/models/User';
import { useStore } from '../../shared/repository/store';
import { LOTTERY_NUMBER } from '../Lottery';

import Lottie from 'react-lottie-player';
import lottieJson from './confetti.json';

function LotteryObs() {
  const { client } = useStore();
  const [avatars, setAvatars] = useState<string[]>([]);
  const [winner, setWinner] = useState<User | null>(null);
  const [coupon, setCoupon] = useState<any | null>(null);
  const [refresh, setRefresh] = useState({});

  // get avatars
  useEffect(() => {
    const getAvatars = async () => {
      const { data } = await client
        .from('users')
        .select('photo')
        .order('last_interacted_at', { ascending: false })
        .limit(72);
      if (data !== null) {
        setAvatars(data.map((user: any) => user.photo));
      }
    };
    getAvatars();
  }, [client]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const syncer = async () => {
      const { data } = await client
        .from('lottery')
        .select('winner(*), coupon')
        .eq('id', 1)
        .single();
      if (data !== null) {
        if (!data.winner && winner !== null) {
          setWinner(null);
          setCoupon(data.coupon);
        }
        if (data.winner && data.winner.id !== winner?.id) {
          setWinner(data.winner);
          setCoupon(data.coupon);
        }
      }
      timer = setTimeout(() => setRefresh({}), 1000);
    };
    syncer();
    return () => clearTimeout(timer);
  }, [refresh, client]);

  const duplicatedAvatars = [
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
    ...avatars,
  ].slice(0, 96);

  return (
    <div className="fixed bg-secondary left-0 top-0 right-0 bottom-0 overflow-hidden flex items-center justify-center">
      <div className="grid grid-cols-12 w-full h-full">
        {duplicatedAvatars.map((avatar, index) => (
          <div
            key={index}
            className="w-full h-full flex items-center justify-center">
            <div
              style={{
                animationDelay: `${((index % 3) * 7 - 35 + index) * 50}ms`,
                backgroundImage: `url(${avatar})`,
              }}
              className="animate-scale flex-shrink-0 w-20 aspect-square rounded-full bg-primary border-4 border-white bg-cover bg-center"
            />
          </div>
        ))}
      </div>
      <div
        className={`${
          !winner ? 'animate-show-from-top' : 'animate-hide-opacity'
        } absolute flex items-center justify-center w-full h-1/5 bg-primary text-white font-bold text-center`}>
        <p
          style={{ fontSize: 40 }}
          className="uppercase tracking-widest animate-show-from-top animate-delay-900">
          rostelecom-sport.ru
        </p>
      </div>
      {winner && (
        <div className="animate-show-scale flex flex-col items-center justify-center absolute w-3/5 aspect-square rounded-full bg-primary">
          <Lottie
            className="absolute w-full aspect-square -mt-40"
            play
            loop={true}
            animationData={lottieJson}
          />

          <div
            className="animate-delay-300 animate-show-from-top bg-cover bg-center w-1/3 aspect-square rounded-full"
            style={{
              backgroundImage: `url(${winner.photo})`,
            }}
          />
          <p className="animate-delay-600 animate-show-from-top text-center text-white text-5xl font-bold mt-10">
            {winner.name}
          </p>
          <p className="animate-delay-900 animate-show-from-top text-4xl text-white font-lottery text-center font-semibold tracking-wider mt-4">
            #
            {winner.number_id! < 501
              ? '1'
              : winner.role === 'member'
              ? 'A'
              : 'B'}
            {padStart(
              winner.number_id!.toString(),
              winner.number_id! < 501 ? 4 : 5,
              '0'
            )}
          </p>
          <p className="relative animate-delay-600 animate-show-from-top text-center text-white text-5xl font-bold mt-10 bg-black px-10 py-5 rounded-xl">
            <div className="absolute w-5 h-5 bg-primary rounded-full -left-2.5 top-1/2 -mt-2.5"></div>
            <div className="absolute w-5 h-5 bg-primary rounded-full -right-2.5 top-1/2 -mt-2.5"></div>
            #{coupon.id}
          </p>
        </div>
      )}
    </div>
  );
}

const MemoizedLotteryObs = memo(LotteryObs);
export { MemoizedLotteryObs as LotteryObs };
