import React, { memo, useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { Navigate } from 'react-router-dom';
import { log } from '../../../shared/helpers/log';
import { num_word } from '../../../shared/helpers/num_words';
import { Transaction } from '../../../shared/repository/models/Transactions';
import { User } from '../../../shared/repository/models/User';
import { useStore } from '../../../shared/repository/store';
import { ShopItem } from '../../Shop';

function Transactions() {
  const { user, client } = useStore();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const syncer = async () => {
      setLoading(true);
      const { data } = await client
        .from('transactions')
        .select('*, created_for(*), shop_item(*), created_by(*)')
        .order('id', { ascending: false })
        .limit(10);

      const { count } = await client
        .from('transactions')
        .select('id', { count: 'exact' });

      if (!data) {
        setLoading(false);
        return;
      }

      setTransactionsCount(count ?? 0);
      setTransactions(data as Transaction[]);
      setLoading(false);
      timer = setTimeout(() => setRefresh({}), 3000);
    };
    syncer();
    return () => clearTimeout(timer);
  }, [refresh]);

  if (user!.role !== 'admin') {
    return <Navigate to="/points" />;
  }

  return (
    <div className="bg-white w-full rounded-xl p-6 flex flex-col">
      <div className="flex w-full justify-between">
        <h3 className="text-xl font-bold flex items-center">
          Транзакции
          <span className="bg-gray-200 px-2 rounded-lg ml-2 py-1 text-xs">
            {transactionsCount}
          </span>
        </h3>
        <button onClick={() => setRefresh({})} className="text-primary">
          {loading ? (
            <RefreshCw className="w-5 h-5 animate-spin" />
          ) : (
            <RefreshCw className="w-5 h-5" />
          )}
        </button>
      </div>
      <div className="flex flex-col mt-4 space-y-4">
        {transactions.map((t) => {
          const o = t.created_for as any as User;
          const shopItem = t.shop_item
            ? (t.shop_item as any as ShopItem)
            : null;
          const author = t.created_by as any as User;

          const highlightedTransaction = false;
          // (o.id === author.id &&
          //   !['quiz', 'match', 'truth'].includes(t.type)) ||
          // (o.role !== 'member' && t.type === 'shop');

          return (
            <div key={t.id} className="flex w-full items-center">
              <div
                className="relative flex-shrink-0 w-10 h-10 rounded-full bg-center bg-cover mr-2"
                style={{
                  backgroundImage: `url(${o.photo!})`,
                }}>
                {highlightedTransaction && (
                  <div
                    style={{ fontSize: 9 }}
                    className=" text-xs font-bold text-white flex items-center justify-center absolute bottom-0 right-0 w-3 h-3 rounded-full bg-red-500 ring-2 ring-white pt-0.5">
                    !
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-bold">{o.name}</p>
                <p className="text-xs text-secondary font-bold">
                  <span className="uppercase tracking-wider">{t.type}</span>
                  {/* {t.type === 'shop' && (
                    <span className=""> — {shopItem!.title}</span>
                  )} */}
                </p>
                {!['quiz'].includes(t.type) && (
                  <div className="text-xs text-gray-500 flex items-center mt-1">
                    <div
                      style={{
                        backgroundImage: `url(${author.photo!})`,
                      }}
                      className="w-4 h-4 rounded-full bg-cover bg-center mr-1"></div>
                    {author.name}
                  </div>
                )}
              </div>
              <div className="flex flex-col ml-auto items-end">
                {t.amount > 0 ? (
                  <p className="text-sm font-bold text-green-500">
                    +{t.amount}
                  </p>
                ) : (
                  <p className="text-sm font-bold text-red-500">{t.amount}</p>
                )}
                <p className="text-xs text-gray-400">#{t.id}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedTransactions = memo(Transactions);
export { MemoizedTransactions as Transactions };
