import React, { memo } from 'react';

function Button({
  title,
  onPress,
  disabled,
  loading,
}: {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
}) {
  return (
    <button
      disabled={disabled || loading}
      onClick={() => onPress()}
      className={`${
        disabled ? 'opacity-50 pointer-events-none' : ''
      } rounded-md bg-primary w-full h-16 text-white font-medium text-xl text-center px-4 flex items-center justify-center`}>
      {loading ? (
        <div className="animate-spin w-5 h-5 border-2 border-white rounded-full border-t-transparent"></div>
      ) : (
        title
      )}
    </button>
  );
}

const MemoizedButton = memo(Button);
export { MemoizedButton as Button };
