import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { useStore } from '../shared/repository/store';
import { Auth } from './Auth';
import Dashboard from './Dashboard';
import { Lottery } from './Lottery';
import { LotteryCheck } from './LotteryCheck';
import { LotteryObs } from './LotteryObs';
import { Main } from './Main';
// import { Match } from './Match';
import { Operator } from './Operator';
import { Profile } from './Profile';
import { Quiz } from './Quiz';
import { Register } from './Register';
import { Shop } from './Shop';
// import { Truth } from './Truth';
// import logo from '../shared/assets/logo.svg';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/points" element={<Main />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/lottery" element={<Lottery />} />
      {/* <Route path="/register" element={<Register />} /> */}
      <Route path="/lottery-check" element={<LotteryCheck />} />
      <Route path="/lottery-obs" element={<LotteryObs />} />
      <Route path="/rating" element={<Main />} />
      <Route path="/operator" element={<Operator />} />
      <Route path="/quiz" element={<Quiz />} />
      {/* <Route path="/truth" element={<Truth />} /> */}
      {/* <Route path="/match" element={<Match />} /> */}
      <Route path="/profile" element={<Profile />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Navigate to="/points" />} />
    </Route>
  )
);

function App() {
  const [loading, setLoading] = useState(true);
  const { client, user, setUser } = useStore();

  useEffect(() => {
    const getUser = async (id: string) => {
      const userData = await client
        .from('users')
        .select()
        .eq('id', id)
        .single();

      const { data } = await client
        .from('transactions')
        .select()
        .eq('created_for', id);

      setUser(
        userData.data
          ? {
              ...userData.data,
              transactions: data ? data : [],
            }
          : { id }
      );
      setLoading(false);
    };

    const onChange = async () => {
      const currentUser = await client.auth.user();
      if (!currentUser) {
        setUser(null);
        setLoading(false);
        return;
      }
      getUser(currentUser.id);
    };
    onChange();
    client.auth.onAuthStateChange(onChange);
  }, []);

  const pathIsLottery = window.location.pathname === '/lottery-obs';
  const pathIsRegister = window.location.pathname === '/register';

  // const blank = true;
  // if (blank) {
  //   return (
  //     <div className="bg-bg container mx-auto px-6 max-w-md">
  //       <div className="flex flex-1 min-h-screen items-center justify-center">
  //         <img src={logo} alt="RT Logo" />
  //       </div>
  //     </div>
  //   );
  // }

  if (loading) {
    return (
      <div className="bg-bg container mx-auto px-6 max-w-md">
        <div className="flex flex-1 min-h-screen items-center justify-center">
          <div className="w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin"></div>
        </div>
      </div>
    );
  }

  if (pathIsRegister) {
    return (
      <div className="bg-bg container mx-auto px-6 max-w-md">
        <Register />
      </div>
    );
  }

  if (!user && !pathIsLottery) {
    return (
      <div className="bg-bg container mx-auto px-6 max-w-md">
        <Auth />
      </div>
    );
  }

  if (user && !user.created_at) {
    return (
      <div className="bg-bg container mx-auto px-6 max-w-md">
        <Profile />
      </div>
    );
  }

  return (
    <div className="bg-bg container mx-auto px-6 max-w-md">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
