import React, { memo, useRef, useState } from 'react';

import Compress from 'react-image-file-resizer';
import { Button, Input } from '../../shared/components';
import { useStore } from '../../shared/repository/store';
import { POLICY_URL } from '../../shared/constants';
import { Camera, Edit2, LogOut, ArrowLeft } from 'react-feather';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';
import { padStart, random } from 'lodash';

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function Profile() {
  const { client, user, setUser } = useStore();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState(user?.name?.split(' ')[0] ?? '');
  const [surname, setSurname] = useState(user?.name?.split(' ')[1] ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [photo, setPhoto] = useState(
    user?.photo ?? `/rtk_avatars/rtk_${random(1, 72).toString()}.png`
  );
  const [imageFile, setImageFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showPhotoHint, setShowPhotoHint] = useState(false);

  const onImageChange = async (e: any) => {
    const file = e.target.files[0];

    // Compress file on client side
    Compress.imageFileResizer(
      file,
      200,
      200,
      'PNG',
      90,
      0,
      (uri: any) => {
        setImageFile(uri);
      },
      'blob'
    );

    // Update visual file
    const reader = new FileReader();
    reader.onload = (event: any) => {
      setPhoto(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const saveUser = async (skip: boolean) => {
    if (!user) {
      return;
    }
    if (imageFile === null && !skip && photo.includes('/rtk_avatars/')) {
      setShowPhotoHint(true);
      return;
    }

    setLoading(true);
    let newPhoto = photo;
    // Update photo
    if (imageFile) {
      const newPhotoPath = `${user.id}/${nanoid(4)}.png`;

      await client.storage.from('avatars').upload(newPhotoPath, imageFile, {
        cacheControl: '3600',
        upsert: true,
      });

      const { publicURL } = client.storage
        .from('avatars')
        .getPublicUrl(newPhotoPath);
      newPhoto = publicURL!;
    }
    const userEntity = {
      id: user.id,
      name: `${name}${surname.length > 0 ? ` ${surname}` : ''}`,
      email,
      photo: newPhoto,
      created_at: user.created_at ?? new Date(),
      last_interacted_at: new Date(),
      active: user.active === undefined ? true : user.active,
    };

    const { data } = await client.from('users').upsert(userEntity);
    setLoading(false);
    if (data) {
      setUser(data[0]);
      window.location.href = '/points';
    }
  };

  const signOut = async () => {
    await client.auth.signOut();
  };

  if (!user) {
    return null;
  }

  const isNewUser = !user.name;
  const canSave =
    name.trim().length > 0 && email.length > 0 && validateEmail(email);

  if (showPhotoHint) {
    const uploadedPhoto = !photo.includes('/rtk_avatars/');
    return (
      <div className="flex-1 text-white items-center justify-center min-h-screen flex">
        <div className="w-full p-6 rounded-xl text-xs flex relative">
          <div className="flex flex-col w-full">
            <label
              className="flex items-center justify-center relative photo bg-center rounded-full w-20 h-20 mx-auto border-2 bg-cover cursor-pointer"
              style={{
                backgroundImage: !uploadedPhoto ? undefined : `url(${photo})`,
              }}>
              <input
                ref={inputFileRef}
                type="file"
                onChange={onImageChange}
                className="hidden"
                accept="image/*"
              />
              {!imageFile && <Camera />}
            </label>

            <h3 className="mt-4 text-xl text-center font-bold mb-4">
              {name}, добавите фото?
            </h3>
            <Button
              loading={loading}
              disabled={loading}
              title={!imageFile ? 'Выбрать фото' : 'Сохранить'}
              onPress={() => {
                !imageFile ? inputFileRef.current?.click() : saveUser(false);
              }}
            />
            <button
              onClick={() => saveUser(true)}
              className={`w-full mt-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-xl focus:outline-none`}>
              Пропустить шаг
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <h1 className="text-2xl text-center font-bold pt-12 flex-shrink-0 text-white">
        Анкета участника
      </h1>
      <p className="text-center mt-3 mb-2 flex-shrink-0 text-white">
        Заполните анкету, чтобы начать получать купоны и участвовать в розыгрыше
      </p>

      <div className="relative flex flex-1 flex-col bg-white mt-20 w-screen max-w-md -mx-6 p-6 pt-0 rounded-t-4xl">
        <button
          onClick={signOut}
          title="Выйти"
          className="-top-10 right-8 absolute p-2 -m-2">
          <LogOut className="text-primary w-5" />
        </button>
        {!isNewUser && (
          <Link to="/points" className="-top-10 left-8 absolute p-2 -m-2">
            <ArrowLeft className="text-primary w-5" />
          </Link>
        )}
        <label
          style={{
            backgroundImage: `url(${photo})`,
          }}
          className="relative w-32 h-32 bg-gray-100 -mt-16 mx-auto mb-6 border-2 border-white rounded-full flex items-center justify-center bg-cover bg-center">
          {!photo && <Camera className="text-gray-500 w-8 h-8" />}
          <input
            type="file"
            onChange={onImageChange}
            className="hidden"
            accept="image/*"
          />
          <div className="absolute right-1 bottom-1 w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center p-2">
            <Edit2 />
          </div>
        </label>

        <Input
          name="name"
          title="Имя"
          value={name}
          onChange={(newName) =>
            setName(newName.replace(/[^а-яА-ЯёЁa-zA-Z]/g, '').slice(0, 50))
          }
        />
        <Input
          name="surname"
          title="Фамилия"
          value={surname}
          onChange={(newName) =>
            setSurname(newName.replace(/[^а-яА-ЯёЁa-zA-Z]/g, '').slice(0, 50))
          }
        />
        <Input
          name="email"
          title="Ваш e-mail"
          value={email}
          onChange={setEmail}
        />
        <div className="my-auto"></div>

        <Button
          loading={loading}
          disabled={!canSave}
          title={isNewUser ? 'Зарегистрироваться' : 'Сохранить'}
          onPress={() => saveUser(false)}
        />
        <a
          href={POLICY_URL}
          rel="noreferrer"
          target="_blank"
          className="text-center mt-2 text-sm text-gray-500 underline">
          Политика конфиденциальности
        </a>
      </div>
    </div>
  );
}

const MemoizedProfile = memo(Profile);
export { MemoizedProfile as Profile };
